(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationCourseController', CertificationCourseController);

    CertificationCourseController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', '$sce', 'entity', 'User', '$state','JhiLanguageService'];

    function CertificationCourseController($scope, $rootScope, $stateParams, previousState, $sce, entity, User, $state, JhiLanguageService) {
        var vm = this;

        if (!User.current) {
            $state.go('certification.enrollment');
        } else {
            if(User.current.testInit) {
                $state.go('certification.questionnaire');
            }

            // This should be load from server from course's document.
            var videos = {
                'callao': {
                    'es': 'https://capacitacion.odfjellterminals.com.pe/videos/odfjell_dqm_induccion_callao_es_1080_highquality.mp4',
                    'en': 'https://capacitacion.odfjellterminals.com.pe/videos/odfjell_dqm_induccion_callao_en_1080_highquality.mp4',
                    'pt-br':'https://capacitacion.odfjellterminals.com.pe/videos/odfjell_dqm_induccion_callao_pt-br_1080_highquality.mp4'}
            };

            JhiLanguageService.getCurrent().then(function (language) {
                vm.video = {
                    sources: [
                        {src: $sce.trustAsResourceUrl(videos[User.current.terminal][language]), type: "video/mp4"},
                    ]
                };
            });
        }
        vm.certificate = entity;
        vm.previousState = previousState.name;
    }
})();
