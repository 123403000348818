(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationEnrollmentController', CertificationEnrollmentController);

    CertificationEnrollmentController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', /*'Person',*/'$state', 'User'];

    function CertificationEnrollmentController ($timeout, $scope, $stateParams, entity, /*Person,*/$state, User) {
        var vm = this;

        vm.person = entity;
        vm.clear = clear;
        vm.save = save;
        User.current = null;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.person.terminal = 'callao'
            User.current = vm.person;
            /*vm.isSaving = true;
            if (vm.person.id !== null) {
                Person.update(vm.person, onSaveSuccess, onSaveError);
            } else {
                Person.save(vm.person, onSaveSuccess, onSaveError);
            }*/
            $state.go('certification.course');
        }

        function onSaveSuccess (result) {
            $scope.$emit('capacitacionCertificacionApp:personUpdate', result);
            vm.isSaving = false;
            $state.go('certification.course');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

