(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('certification', {
            parent: 'entity',
            url: '/certificaciones',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification.html',
                    controller: 'CertificationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('video');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('certification.questionnaire', {
            parent: 'certification',
            url: '/evaluacion',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.questionnaire.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification-questionnaire.html',
                    controller: 'CertificationQuestionnaireController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('certification');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Course','User','$state','JhiLanguageService', function($stateParams, Course, User,$state,JhiLanguageService) {
                    // To improve!
                    var courseId = {
                        'callao': {
                            'es': '5cae055e33abb65cdc757867',
                            'en': '5cae054b33abb65cdc757866',
                            'pt-br': '5cae057d33abb65cdc757868'}
                    };
                    return JhiLanguageService.getCurrent()
                    .then(function (currentLanguage) {
                        return Course.generateExam({id : courseId[User.current.terminal][currentLanguage]},User.current).$promise;
                    }).catch(function(error){
                        console.log("Error:")
                        console.log(error)
                        $state.go('certification.enrollment');
                    });

                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'certification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('certification.enrollment', {
            parent: 'certification',
            url: '/inscripcion',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.enrollment.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification-enrollment.html',
                    controller: 'CertificationEnrollmentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('person');
                    $translatePartialLoader.addPart('certification');
                    $translatePartialLoader.addPart('documentTypes');
                    $translatePartialLoader.addPart('accessType');
                    $translatePartialLoader.addPart('terminals');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        name: null,
                        surname: null,
                        documentType: null,
                        documentNumber: null,
                        company: null,
                        id: null
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'certification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('certification.result', {
            parent: 'certification',
            url: '/resultado',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.result.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification-result.html',
                    controller: 'CertificationResultController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('person');
                    $translatePartialLoader.addPart('certification');
                    $translatePartialLoader.addPart('certificate');
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        name: 'Martin',
                        surname: 'Mihura',
                        documentType: 'DNI',
                        documentNumber: 31323545,
                        company: 'DbLandIT',
                        expirationDate: new Date('2017-11-03')
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'certification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('certification.course', {
            parent: 'certification',
            url: '/curso',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.course.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification-course.html',
                    controller: 'CertificationCourseController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('person');
                    $translatePartialLoader.addPart('certification');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        name: 'Martin',
                        surname: 'Mihura',
                        documentType: 'DNI',
                        documentNumber: 31323545,
                        company: 'DbLandIT',
                        expirationDate: new Date('2017-11-03')
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'certification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('certification.underdev', {
            parent: 'certification',
            url: '/en-desarrollo',
            data: {
                pageTitle: 'capacitacionCertificacionApp.certification.course.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/certification/certification-underdev.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'certification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
    }

})();
